.login_box {
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
  width: 90%;
  max-width: 25rem;
  margin: auto;
  text-align: center;
}

/*
.center{
  text-align: center;
  font-family: 'Red Hat Text';
  margin-bottom: 5rem;
}
*/