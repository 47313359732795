@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-6xl;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-2xl;
  }
  h4 {
    @apply text-lg;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply font-semibold;
  }
}

@layer components {
  .categoryMenu {
    @apply appearance-none
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none;
  }

  .landingExplainer1{
    @apply mx-48 py-4 px-24 font-main leading-loose text-lg text-white font-medium text-center bg-black rounded-xl
  }
}
