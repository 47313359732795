.accordionContainer {
  font-family: "Red Hat Text";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 2rem;
  margin-bottom: 4rem;
}

.questionSet {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1.5% 3.5%;
  /* font-size: 1.25rem; */
}

.faqQuestion {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.75);
}

.faqAnswer {
  margin-top: 0.7rem;
}

.faqPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#shareLink {
  padding-bottom: 2rem;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
}
