textarea {
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.7);
  border: solid rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.submitArticleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-bottom: 3rem;
}

.articleSection {
  display: flex;
  flex-direction: column;
  /* width: 50vw; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
}

.articleContainer {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  gap: 1.5rem;
}

.articleHeader {
  /* font-size: 2.5rem; */
  color: rgba(0, 0, 0, 0.75);
}

.articleTitleInput {
  width: 100%;
  height: 7rem;
  font-size: 1.5rem;
}

.articleContentInput {
  width: 100%;
  height: 20rem;
  font-size: 1.5rem;
}

.submitArticleTitle {
  width: 20rem;
  height: 3rem;
  border: none;
  border-radius: 20px;
  background-color: aquamarine;
}

.categorySelect {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: -2rem;
}

.editor-style {
  min-height: 6rem;
}
