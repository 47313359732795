.columnBody {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.articleList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.columnSubTitle {
  display: flex;
  justify-content: center;
  font-family: "Red Hat Text";
  color: rgba(0, 0, 0, 0.8);
}

#shareLink {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
}
