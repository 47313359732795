.articlePreviewContainer {
  /* display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: 20% 20% 20% 20% 20%; */
  font-family: "Red Hat Text";
  padding: 3% 3%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* width: 1100px; */
  /* gap: 0.5rem; */
  cursor: pointer;
  background-color: #f7fafc;
}

.articlePreviewTitle {
  /* grid-column: 1/5;
  grid-row: 1/4; */
  font-size: 2rem;
  /* align-self: center; */
}

/* .articlePreviewCategory {
  grid-column: 5/6;
  grid-row: 1/2;
  align-self: end;
  justify-self: center;
}

.articlePreviewBody {
  grid-column: 1/5;
  grid-row: 2/6;
  padding-bottom: 2rem;
} */

.articleIcon {
  /* grid-column: 5/6;
  grid-row: 1/6; */
  height: 100px;
  border-radius: 50%;
  /* justify-self: center;
  align-self: start; */
}
