footer {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: "Red Hat Text";
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  background: #000000;
  text-align: left;
  padding: 1% 2%;
  padding-top: 2%;
}
