.proposal_list {
  /* background-color: aquamarine; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-template-rows: auto;
  grid-row-gap: 3rem;
  align-items: center;
  justify-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
}
