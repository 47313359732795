.cardContainer {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  font-family: "Red Hat Text";
  padding: 4% 4%;
  min-height: 280px;
  width: 245px;
  gap: 2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.title {
  align-self: center;
  text-align: center;
}

.cardContainer img {
  height: 105px;
  width: auto;
  align-self: center;
  justify-self: center;
}

.proposalCategory {
  align-self: center;
  margin-bottom: -2rem;
}

.upvotesContainer img {
  height: 18px;
  width: auto;
}
