.customButton {
  font-family: "Red Hat Text";
  height: 3rem;
  border-style: none;
  /* background-color: #f5f5f5; */
  border-radius: 15px;
  padding: 0 1rem;
  text-transform: uppercase;
}

.customButton:active {
  transform: scale(0.97);
}
