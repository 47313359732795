/* .searchSection {
  display: flex;
  gap: 1rem;
  justify-content: center;
} */
/*
.gridContainer {
  display: flex;
  justify-content: center;
}
*/

/* .proposalsBody {
  margin-bottom: 4rem;
} */

/* 
.proposalGrid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto;
  grid-row-gap: 4rem;
  width: 1400px;
  align-items: center;
  justify-items: center;
  margin-top: 3rem;
}
*/

/* .proposalsHomeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: aqua;
}

#shareLink {
  margin-bottom: 3rem;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
} */

.paginateContainer {
  display: flex;
  flex-direction: column;
}

.paginateContainer button {
  align-self: center;
}
